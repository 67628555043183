//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      password: '',
      email: '',
      showLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      darkTheme: 'getDarkTheme',
    }),
  },
  methods: {
    ...mapActions({
      userLogin: 'userLogin',
    }),
    adminLogin() {
      const that = this;
      if (!that.inValid(that.email)) {
        this.$q.notify({
          progress: true,
          type: 'negative',
          closeBtn: true,
          message: that.$t('login.errors.title'),
          caption: that.$t('login.errors.content'),
          timeout: 2000,
          position: 'top',
        });
      } else if (!that.inValid(that.password)) {
        this.$q.notify({
          progress: true,
          type: 'negative',
          closeBtn: true,
          message: that.$t('login.errors.title'),
          caption: that.$t('login.errors.passwordError'),
          timeout: 2000,
          position: 'top',
        });
      } else if (!that.validateEmail(that.email)) {
        this.$q.notify({
          progress: true,
          type: 'negative',
          closeBtn: true,
          message: that.$t('login.errors.title'),
          caption: that.$t('login.errors.invalidEmail'),
          timeout: 2000,
          position: 'top',
        });
      } else {
        that.showLoading = true;
        const data = {
          email: that.email,
          password: that.password,
        };
        that.userLogin(data).then((res) => {
          try {
            this.$router.push({
              name: 'aiProjects',
            }).catch(()=>{});
            this.$q.notify({
              progress: true,
              type: 'positive',
              caption: that.$t('login.success'),
              timeout: 500,
              position: 'top',
            });
          } catch (err) {
            console.log('staticLogin err', err);
          }
        })
          .catch((err) => {
            this.$q.notify({
              progress: true,
              type: 'negative',
              closeBtn: true,
              message: that.$t('login.errors.loginErr'),
              caption: err.errmsg,
              timeout: 2000,
              position: 'top',
            });
          })
          .finally(() => {
            that.showLoading = false;
          });
      }
    },
    inValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
